var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9a96e36efd0bc444739239574a137c1d444f3514"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { sentryClientOpts } from '@comparacar/lib/src/sentry-opts'


Sentry.init({
    ...sentryClientOpts,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    maskAllText: false,
    integrations: [new Sentry.Replay()]
})
