import { autocomplete } from '@comparacar/lib/src/api/comparacar/autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import InputAdornment from '@mui/material/InputAdornment'
import ListSubheader from '@mui/material/ListSubheader'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Link from 'next/link'
import NextLink from 'next/link'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import CarmatchButton from './CarMatchButton'
import isPageTransitioningState from '../state/isPageTransitioning'

export const SearchInputIcon = styled(SearchIcon)(({ theme }) => ({
  fontSize: '28px',
  marginLeft: '18.5px',
  color: theme.palette.text.primary
}))

interface iSearchInputProps {
  onSelect: (option: AutocompleteType) => void
  fullWidth?: boolean
  hideSeeAllButton?: boolean
  hideCarmatch?: boolean
  hideManufacture?: boolean
  engine?: string
}

const SearchInput: React.FC<iSearchInputProps> = ({
  engine = 'subscribe',
  onSelect,
  fullWidth,
  hideCarmatch,
  hideSeeAllButton,
  hideManufacture
}) => {
  const [options, setOptions] = useState<AutocompleteType[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const isPageTransitioning = useRecoilValue(isPageTransitioningState)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedValue, setSelectedValue] = useState<AutocompleteType | null>(null)

  const fetch = async () => {
    setLoading(true)
    const result = await autocomplete(searchTerm, hideManufacture || false)
    setOptions(result)
    setLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [hideManufacture, searchTerm])

  const isDisabled = option => engine === 'subscribe' && option.disabled

  return (
    <>
      <Box sx={{ flex: 1 }}>
        <Autocomplete
          id="main-search-input"
          disabled={isPageTransitioning}
          ListboxProps={{
            style: {
              padding: 0
            }
          }}
          fullWidth={fullWidth}
          autoHighlight={true}
          disableClearable
          selectOnFocus
          clearOnBlur={true}
          options={options}
          noOptionsText="Veículo não encontrado"
          groupBy={(option: AutocompleteType) => option.type}
          loading={loading}
          loadingText="Carregando..."
          onChange={(_e, newValue: AutocompleteType) => {
            onSelect(newValue)
          }}
          value={selectedValue}
          inputValue={searchTerm || ''}
          onInputChange={(_e, newInputValue: string) => {
            setSearchTerm(newInputValue)
            setSelectedValue(null)
          }}
          renderInput={params => {
            return (
              <TextField
                {...params}
                placeholder="Digite a marca ou modelo do carro e selecione"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchInputIcon />
                    </InputAdornment>
                  ),
                  endAdornment: null,
                  disableUnderline: true
                }}
              />
            )
          }}
          filterOptions={x => x}
          getOptionDisabled={option => isDisabled(option)}
          renderOption={(props, option: AutocompleteType, { inputValue }) => {
            const matches = match(option.label, option.highlight || inputValue)
            const parts = parse(option.label, matches)
            return (
              <Box component="li" sx={{ width: '100%' }} data-testid={option.value} {...props}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>{' '}
                {isDisabled(option) && (
                  <Box component="span" ml={1}>
                    (Indisponível para assinatura)
                  </Box>
                )}
              </Box>
            )
          }}
          renderGroup={({ group, children }) => {
            const replacers = { manufacture: 'Marca', model: 'Modelo' }
            return (
              <>
                <ListSubheader>
                  {replacers[group]}
                  <Divider />
                </ListSubheader>
                {children}
              </>
            )
          }}
        />
      </Box>
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
        {!hideSeeAllButton && (
          <NextLink href="/assinar/carro" passHref legacyBehavior>
            <Button component={Link} disabled={isPageTransitioning} variant="contained">
              VER OFERTAS
            </Button>
          </NextLink>
        )}

        {!hideCarmatch && <CarmatchButton isPageTransitioning={isPageTransitioning} />}
      </Stack>
    </>
  )
}

export default SearchInput
