import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useRouter } from 'next/router'
import React from 'react'
import { ErrorBoundary as Component } from 'react-error-boundary'

import SearchInput from './SearchInput'
import CarBrokenIcon from '../icons/CarBroken'

function ErrorFallback({ resetErrorBoundary }) {
  const router = useRouter()
  const handleSelect = (option?: AutocompleteType | null) => {
    const newQuery = option ? option.value.split('\\') : []
    router.push({
      pathname: router.pathname,
      query: {
        slug: newQuery,
        sort: router.query.sort || undefined
      }
    })
    resetErrorBoundary()
  }

  return (
    <Box sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh' }}>
      <Box textAlign="center" fontSize="10em">
        <CarBrokenIcon fontSize="inherit" />
      </Box>
      <br />
      <br />
      <Typography variant="h5">Algo deu errado! Lamentamos o transtorno</Typography>
      <Typography variant="h5">Tente procurar por algo diferente.</Typography>
      <br />
      <br />
      <br />
      <br />
      <SearchInput fullWidth onSelect={option => handleSelect(option)} />
    </Box>
  )
}

const logError = (error: Error, info: { componentStack: string }) => {
  console.error(info, error)
}

const ErrorBoundary: React.FC<React.PropsWithChildren> = ({ children }) => {
  // @ts-ignore
  return (
    <Component FallbackComponent={ErrorFallback} onError={logError}>
      {children}
    </Component>
  )
}

export default ErrorBoundary
