import * as gtag from '@comparacar/lib/src/utils/analytics'
import { Alert, Snackbar } from '@mui/material'
import router, { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import isPageTransitioning from '../state/isPageTransitioning'
import referralState from '../state/referral'

interface PageTransitionInterface {
  children: ReactNode
}

const PageTransitionWrapper: React.FC<PageTransitionInterface> = ({ children }) => {
  const setFilterSearch = useSetRecoilState(isPageTransitioning)
  const [referral, setReferral] = useRecoilState(referralState)
  const [snackMessage, setSnackMessage] = useState('')

  const { query } = useRouter()

  useEffect(() => {
    if (query.utm_source) {
      const utm = {
        source: query.utm_source as string,
        medium: query?.utm_medium as string,
        campaign: query?.utm_campaign as string
      }
      const date = new Date()
      if (utm.campaign) {
        const parsedCampaign = utm.campaign
          .split('_')
          .map(w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
          .join(' ')
        setSnackMessage(`Campanha: ${parsedCampaign} ativada!`)
      }
      setReferral({ utm, date })
    }

    const handleComplete = url => {
      gtag.pageview(url)
      setFilterSearch(false)
    }

    const handleStart = () => {
      setFilterSearch(true)
    }

    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeStart', handleStart)

    return () => {
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeStart', handleStart)
    }
  }, [router.events, query])

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!snackMessage}
        onClose={() => setSnackMessage('')}
        autoHideDuration={5000}
      >
        <Alert onClose={() => setSnackMessage('')} severity="success" sx={{ width: '100%' }}>
          {snackMessage}
        </Alert>
      </Snackbar>
      {children}
    </>
  )
}

export default PageTransitionWrapper
