import { createTheme } from '@mui/material'
import { deepmerge } from '@mui/utils'
import { Inter } from 'next/font/google'

const inter = Inter({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  display: 'swap'
})

const defaultTheme = createTheme()

let customTheme = createTheme({
  typography: {
    fontFamily: inter.style.fontFamily
  },
  palette: {
    header: {
      background: defaultTheme.palette.background.paper,
      color: defaultTheme.palette.primary.main,
      logo1: '#0980B3',
      logo2: '#FFA741'
    },
    primary: {
      light: '#0FAFF2',
      main: '#0980B3',
      dark: '#06587C',
      contrastText: '#FFFFFF'
    },
    secondary: {
      light: '#FFDDB7',
      main: '#FFA741',
      dark: '#DD7700',
      contrastText: '#FFFFFF'
    },
    error: {
      light: '#F53D36',
      main: '#D1110A',
      dark: '#900C07',
      contrastText: '#FFFFFF'
    },
    success: {
      light: '#15BC76',
      main: '#0F8A56',
      dark: '#0A5F3B',
      contrastText: '#FFFFFF'
    },
    info: {
      light: '#38BDF4',
      main: '#0B96D1',
      dark: '#076790',
      contrastText: '#FFFFFF'
    },
    warning: {
      light: '#F6B95C',
      main: '#EB920C',
      dark: '#A26408',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: 'rgba(20, 26, 29, 0.87)',
      secondary: 'rgba(20, 26, 29, 0.6)',
      disabled: 'rgba(20, 26, 29, 0.38)'
    },
    background: {
      paper: '#FFFFFF',
      default: '#F8F9FA'
    },
    action: {
      active: 'rgba(20, 26, 29, 0.54)',
      hover: 'rgba(20, 26, 29, 0.04)',
      hoverOpacity: 0.1,
      selected: 'rgba(20, 26, 29, 0.08)',
      selectedOpacity: 0.1,
      disabled: 'rgba(20, 26, 29, 0.26)',
      disabledBackground: 'rgba(20, 26, 29, 0.12)',
      disabledOpacity: 0.38,
      focus: 'rgba(20, 26, 29, 0.12)',
      focusOpacity: 0.05,
      activatedOpacity: 0.12
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      :root {
        body {
          background-color: #f8f9fa;
          color: rgba(20, 26, 29, 0.87);
        }
      }
    `
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
          fontWeight: '500'
        },
        sizeMedium: {
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.4px'
        },
        sizeSmall: {
          fontSize: '13px',
          lineHeight: '22px',
          letterSpacing: '0.46px'
        },
        sizeLarge: {
          fontSize: '15px',
          lineHeight: '26px',
          letterSpacing: '0.46px'
        }
      }
    }
  }
})

customTheme.typography = {
  ...customTheme.typography,
  h1: {
    ...customTheme.typography.h1,
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '123.5%',
    letterSpacing: '0.25px'
  },
  h2: {
    ...customTheme.typography.h2,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px'
  },
  h3: {
    ...customTheme.typography.h3,
    fontWeight: 500,
    fontSize: '32px',
    lineHeight: '39px',
    letterSpacing: '0px'
  },
  h4: {
    ...customTheme.typography.h4,
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '123.5%',
    letterSpacing: '0.25px'
  },
  h5: {
    ...customTheme.typography.h5,
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '133.4%',
    letterSpacing: '0px'
  },
  h6: {
    ...customTheme.typography.h6,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px'
  },
  body1: {
    ...customTheme.typography.body1,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '150%',
    letterSpacing: '0.15px'
  },
  body2: {
    ...customTheme.typography.body2,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px'
  },
  button: {
    ...customTheme.typography.button,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px'
  },
  caption: {
    ...customTheme.typography.caption,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '166%',
    letterSpacing: '0.4px'
  },
  subtitle1: {
    ...customTheme.typography.subtitle1,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '175%',
    letterSpacing: '0.15px'
  },
  subtitle2: {
    ...customTheme.typography.subtitle2,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '157%',
    letterSpacing: '0.1px'
  },
  overline: {
    ...customTheme.typography.overline,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '266%',
    letterSpacing: '1px'
  }
}

const localizaTheme = createTheme(
  deepmerge(customTheme, {
    palette: {
      header: {
        background: '#01602A',
        color: '#fff',
        logo1: '#fff',
        logo2: '#fff'
      },
      primary: {
        main: '#01602A',
        light: '#337f54',
        dark: '#00431d'
      }
    }
  })
)

const movidaTheme = createTheme(
  deepmerge(customTheme, {
    palette: {
      header: {
        background: '#FF5000',
        color: '#fff',
        logo1: '#fff',
        logo2: '#fff'
      },
      primary: {
        main: '#FF5000',
        light: '#FFEBE1',
        dark: '#b23800'
      }
    }
  })
)

const unidasTheme = createTheme(
  deepmerge(customTheme, {
    palette: {
      header: {
        background: '#0086FF',
        color: '#fff',
        logo1: '#fff',
        logo2: '#fff'
      },
      primary: {
        main: '#0086FF',
        light: '#D9F7FF',
        dark: '#005db2'
      }
    }
  })
)

export { customTheme, localizaTheme, unidasTheme, movidaTheme }
