import { customTheme, localizaTheme, movidaTheme, unidasTheme } from '@comparacar/lib/src/theme'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { ReactNode, useEffect, useState } from 'react'

interface PageTransitionInterface {
  children: ReactNode
  provider: string
}

const themes = {
  Localiza: localizaTheme,
  Movida: movidaTheme,
  'Unidas Livre': unidasTheme
}

const PageProvider: React.FC<PageTransitionInterface> = ({ children, provider }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  if (!isLoaded) return null

  const theme = themes[provider] || customTheme

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default PageProvider
